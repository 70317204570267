@import "variables";

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: $primary-color;
}
