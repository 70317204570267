@import "variables";

.copy {
  color: #ccc;
  font-size: 14px;

  a {
    font-weight: normal;
    color: $primary-color;
  }
}

.header-wrapper {
  padding: 10px 0;
  position: fixed;
  width: 30%;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: $screen-view;
  margin: 0 auto;

  background: url('../images/sixsix.jpeg');
  background-size: 130% auto;
  background-position: -20px 0;
  background-repeat: no-repeat;

  .author {
    &-title {
      color: #fff;
      font-size: 43px;
      font-weight: 900;
      font-family: $title-font-family;
    }

    &-desc {
      margin: 0.5em 0;
      line-height: 1.2;
      letter-spacing: 1px;
      color: #ccc;
      font-size: 16px;
    }
  }

  .header-content {
    position: absolute;
    bottom: 0;
    padding: 60px 60px 52px;

    @media(max-width: 768px) {
      .copy {
        display: none;
      }
    }
  }

  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 30px auto;
    overflow: hidden;
  }

  .header-image {
    position: fixed;
    top: 0;
    width: 30%;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .header-overlay {
    background: linear-gradient(to bottom,rgba(22,27,33,0) 0,rgba(22,27,33,.3) 1%,rgba(22,27,33,.8) 70%,rgba(22,27,33,.9) 100%);
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .logo {
    float: left;
    width: 180px;
    margin-top: 5px;

    img {
      width: 100%;
    }
  }

  .nav {
    list-style: none;
    padding: 0;
    font-size: 18px;
    margin-top: 30px;

    li {
      margin: 10px 0;
      a {
        text-decoration: none;
        color: #ccc;

        &:hover,
        &.active {
          color: $primary-color
        }
      }
    }
  }

  &:after {
    display: block;
    content: '';
    clear: both;
  }

  .social {
    padding: 0;
    list-style: none;
    margin-bottom: 30px;

    a {
      text-decoration: none;
      font-size: 28px;
      color: #d0cece;

      + a {
        margin-left: 20px;
      }

      &:hover,
      &:active {
        color: $primary-color;
      }
    }
  }
}

.page-content {
  max-width: 100%;
  padding-left: 30%;
  border: 1px solid transparent;
  min-height: 500px;

  .wrapper {
    padding: 30px 120px 30px 60px;
    overflow: hidden;
  }
}

.page-wrapper {
  @media (max-width: 1200px) {
    .header-wrapper {
      width: 40%;
    }

    .page-content {
      padding-left: 40%;

      .wrapper {
        padding: 30px 60px;
      }
    }
  }

  @media (max-width: 998px) {
    .header-wrapper {
      width: 45%;
    }

    .page-content {
      padding-left: 45%;

      .wrapper {
        padding: 30px 30px;
      }
    }
  }

  @media (max-width: 768px) {
    .header-wrapper {
      width: 100%;
      position: relative;
      padding: 1px 15px 30px;

      .author-title {
        text-align: center;
      }
    }

    .header-content {
      position: relative;
      margin: 0 auto;
      padding: 0;
    }

    .page-content {
      padding: 0;

      .wrapper {
        padding: 30px 15px;
      }
    }
  }
}

.footer {
  display: none;
  padding: 50px 0;
  text-align: center;
  background: #000;

  .copy {
    margin: 0 auto;
  }

  @media(max-width: 768px) {
    display: block;
  }
}
