// Place all the styles related to the posts controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/

@import "variables";

.time-wrapper {
  color: $assist-color;
  font-size: 14px;

  time + time {
    margin-left: 1em;
  }

  @media (max-width: 768px) {
    time {
      display: block;
    }

    time + time {
      margin: 0;
    }
  }
}

.tag-list {
  vertical-align: 2px;

  .tag {
    font-size: 12px;
    border: 1px solid;
    display: inline-block;
    margin: 2px 0;
    border-radius: 2px;
    color: $primary-color;
    padding: 2px 10px;
  }

  &.new-line {
    margin: 6px 0;
  }
}

.post {
  .post-title {
    font-size: 34px;
    margin: 0.2em 0 0.3em;
  }

  ol, ul {
    padding: 0px;
    margin: 8px 0;
    padding-left: 16px;

    li {
      line-height: 1.5;
      font-size: 14px;
    }
  }

  .post-content {
    line-height: 1.4;
    margin-top: 1em;

    h1, h2, h3, h4, h5, h6 {
      margin: 0.8em 0 0.2em;
    }

    h1 {
      position: relative;
      padding-left: 16px;

      &:after {
        color: $primary-color;
        content: '\f12d';
        font-family: 'Font Awesome 5 Free';
        position: absolute;
        left: 0;
        font-size: 12px;
      }
    }

    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 22px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 16px;
    }

    h6 {
      font-size: 14px;
    }

    > p,
    > strong {
      letter-spacing: 1px;
    }

    p {
      margin: 10px 0;
      font-size: 15px;
      line-height: 1.5;
    }

    img {
      width: 100%;
      display: block;
      margin: 1em auto;
    }

    code {
      font-size: 13px;
    }

    p {
      > code {
        font-size: 13px;
        display: inline-block;
        margin: 0;
        color: #2381e6;
        font-weight: bold;
      }
    }

    em {
      color: #b9b9b9;
    }

    a {
      text-decoration: underline;
    }

    blockquote {
      margin: 0;
      font-size: 1.2em;
      border-left: 5px solid $primary-color;
      padding: 10px;
      font-style: italic;
      background: $background-color;
      color: darken($background-color, 50%);

      p {
        margin: 0;
        font-size: 15px;
      }
    }
  }
}

// The list of post
.post-list {
  margin: 0;
  padding: 0;
  list-style: none;

  .empty {
    margin: 0;
  }

  // Home page with cover
  .post-wrapper {
    .post-meta {
      .time-wrapper, .tag-list {
        display: inline-block;
      }

      .time-wrapper {
        margin-right: 2em;
      }
    }

    ~ .post-wrapper {
      margin-top: 40px;
    }
  }

  .post-title {
    font-size: 34px;
    font-weight: bold;
    margin: 0.2em 0 0.3em;
    color: #333;
    display: block;
    text-decoration: none;

    &:hover,
    &:active {
      color: #000;
    }
  }

  .post-excerpts {
    color: $content-color;
    font-size: 14px;
    line-height: 1.4;
    margin: 10px 0;
  }

  .read-more {
    display: inline-block;
    text-decoration: none;
    padding: 8px 10px;
    background: $primary-color;
    font-size: 16px;
    color: #fff;

    &:hover,
    &:active {
      background: darken($primary-color, 10%);
    }
  }

  // Blog page without cover
  .inline-post-wrapper {
    margin-bottom: 10px;

    .title-wrapper {
      a {
        color: $primary-color;
        text-decoration: none;
        letter-spacing: 1px;

        &:hover,
        &:active {
          color: darken($primary-color, 20%);
        }
      }
    }
  }
}

.pager {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  font-size: 18px;

  em, a {
    font-style: normal;
    width: 15px;
    display: inline-block;
    height: 15px;
    text-align: center;
  }
}


@media(max-width: 768px) {
  .post-list {
    .post-title {
      font-size: 28px;
    }
  }

  .post {
   .post-title {
     font-size: 28px;
   }
 }
}
