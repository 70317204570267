// Place all the styles related to the about controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/

@import "variables";

.about {
  max-width: 600px;
  .banner {
    height: 266px;
    overflow: hidden;

    img {
      margin-top: -105px;
    }
  }

  .title {
    font-size: 30px;
    margin: 0;
    position: relative;
    padding-left: 16px;

    &:after {
      color: $primary-color;
      content: '\f007';
      font-family: 'Font Awesome 5 Free';
      position: absolute;
      top: 4px;
      left: 0;
      font-size: 12px;
    }
  }

  p {
    margin: 10px 0;
    line-height: 27px;
    letter-spacing: 0.8px;
    color: $content-color;
  }

  .resume {
    margin-top: 10px;

    + .resume {
      margin-top: 30px;
    }
  }
}
